import './AccordionList.scss';

import { Accordion as AccordionItem } from '@travelwin/core';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';

const className = 'c-AccordionList';

export type AccordionItemType = {
  key: string;
  label: string | ReactNode;
  subLabel?: string | null | undefined;
  content: ReactNode;
};

type AccordionListVariant = 'primary' | 'text' | 'neutral';

interface Props {
  items: Array<AccordionItemType>;
  variant?: AccordionListVariant;
}

export const AccordionList = ({ items, variant }: Props) => {
  const [isAccordionItemOpen, setIsAccordionItemOpen] = useState<
    Record<AccordionItemType['key'], boolean>
  >(() => Object.fromEntries(items.map(({ key }) => [[key], false])));

  return (
    <>
      {items.map(({ key, label, subLabel, content }, idx) => (
        <div key={key}>
          {idx > 0 ? <div className={`${className}__separator`} /> : null}
          <AccordionItem
            label={label}
            subLabel={subLabel}
            className={classNames(className, {
              [`${className}--${variant}`]: !!variant,
            })}
            open={isAccordionItemOpen[key]}
            onClick={() =>
              setIsAccordionItemOpen({
                ...isAccordionItemOpen,
                [key]: !isAccordionItemOpen[key],
              })
            }
          >
            <ol>{content}</ol>
          </AccordionItem>
        </div>
      ))}
    </>
  );
};
