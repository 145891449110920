import './Accordion.scss';

import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { MouseEventHandler, ReactNode } from 'react';

import ControllerButton from '../Inputs/ControllerButton';

type AccordionProps = {
  label?: string | React.ReactNode;
  subLabel?: string | null | undefined;
  /** To show/hide content we pass the state of open as a boolean */
  open: boolean;
  /** onClick to be applied to the header */
  onClick: MouseEventHandler<HTMLElement>;
  className?: string;
  children: ReactNode;
};

const Accordion = ({
  label,
  subLabel,
  open,
  onClick,
  className,
  children,
}: AccordionProps) => {
  const componentClassName = 'c-Accordion';

  const renderTitleClass = () => {
    return `${componentClassName}__title${
      open ? ` ${componentClassName}--is-expanded` : ''
    }`;
  };

  return (
    <div
      className={`${className ? `${className} ` : ''}${componentClassName}`}
      data-testid="button-accordion"
    >
      <dt
        onClick={onClick}
        className={renderTitleClass()}
        data-testid="dt-accordion-expand"
      >
        {label && typeof label === 'string' && (
          <span className={`${componentClassName}__controller-label`}>
            {label}
          </span>
        )}
        {typeof label !== 'string' && label}
        {subLabel && (
          <span className={`${componentClassName}__controller-sublabel`}>
            {subLabel}
          </span>
        )}
        <span className={`${componentClassName}__controller-container`}>
          <ControllerButton aria-label="show or hide content">
            {open ? (
              <CaretUp size={24} weight="bold" color="white" />
            ) : (
              <CaretDown size={24} weight="bold" color="white" />
            )}
          </ControllerButton>
        </span>
      </dt>

      <dd
        className={`${componentClassName}__content${
          open ? ` ${componentClassName}--is-expanded` : ''
        }`}
        data-testid="dd-accordion-content"
      >
        <div className={`${componentClassName}__body`}>{children}</div>
      </dd>
    </div>
  );
};

export default Accordion;
